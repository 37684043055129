angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('main.contacts_groups', {
            url: '/configuration/contacts/groups',
            views: {
                "main": {
                    controller: 'ContactsGroupsCtrl',
                    templateUrl: 'web/main/contacts/contactGroup.tpl.html',
                    controllerAs: 'controller'
                }
            },
            resolve: {
                grupos: function (contactService) {
                    return contactService.getContactosGrupos();
                }
            },
            data: {
                pageTitle: 'Contacts groups'
            }
        });
    })



    .controller('ContactsGroupsCtrl', function ($scope, $window, session, contactService, $state, swalService, grupos, $translate, createContactGroupModal) {
        var that = this;
        that.grupos = grupos.data;
        that.checkMobile = $window.innerWidth < 768;





        that.anyadirGrupo = function () {
            createContactGroupModal.openModal().result.then(function (r) {
                if (r) {
                    contactService.getContactosGrupos().then(function (response) {
                        that.grupos = response.data;
                    });
                }
            });

        };

        that.deleteGrupo = grupo => swalService.requestSwal('¿Estás seguro?', 'Se va a borrar este grupo, esta acción no se puede deshacer', 'warning', () => contactService.deleteContactoGrupo(grupo.id)).then(response => {
            swal($translate.instant('¡Correcto!'), $translate.instant('Se ha eliminado el grupo'), 'success');
            contactService.getContactosGrupos().then(response => that.grupos = response.data);
        });


        that.editarGrupo = function (grupo) {
            $state.go('main.configuration_edit_contacts_groups', {
                id: grupo.id
            });
        };






    });
